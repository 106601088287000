import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleTableComponent } from './simple-table/simple-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleConfirmComponent } from './simple-confirm/simple-confirm.component';
import { AuthService } from '@shared/auth/auth.service';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { SimpleColumnTemplateDirective } from './simple-table/simple-column-template.directive';

@NgModule({
  imports: [TranslateModule, CommonModule, ReactiveFormsModule, NgbPagination, FormsModule],
  declarations: [SimpleTableComponent, SimpleConfirmComponent, SimpleColumnTemplateDirective],
  exports: [SimpleTableComponent, SimpleColumnTemplateDirective],
  providers: [AuthService],
})
export class SharedModule {}
