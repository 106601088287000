import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Shell } from '@app/shell/shell.service';
import { AuthenticationComponent } from '@app/authentication/authentication.component';
import { NgModule } from '@angular/core';
import { ForceResetComponent } from '@app/authentication/force-reset/force-reset.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/auth', pathMatch: 'full' },
    { path: 'auth', component: AuthenticationComponent, data: { title: marker('Login') } },
    { path: 'auth/force_reset', component: ForceResetComponent, data: { title: marker('Reset Password') } },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthenticationRoutingModule {}
