import { NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export class BaseState {
  protected constructor(private toastr: ToastrService, private zone: NgZone) {}

  runInZone(fn: () => void): void {
    this.zone.run(() => fn());
  }

  showMessage(message: string) {
    this.toastr.success(message);
  }

  showError(message: string) {
    this.toastr.error(message);
  }

  showUnknownError(err?: Error) {
    if (err) {
      // if it's an authorization error suppress the message as we'll redirect the
      // user to the login page
      if (err.message !== 'Authorization error') {
        this.toastr.error(err.message);
      }
    } else {
      this.toastr.error('An unknown error has occurred.');
    }
  }

  successResponse(response: any) {
    if (response.status && response.status === 'error') {
      this.toastr.error(response.message);
      return false;
    }

    return true;
  }
}
