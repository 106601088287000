import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthenticationState } from '@app/authentication/state/authentication.state';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent {
  @Select(AuthenticationState.authenticated) authenticated$!: Observable<boolean>;

  get authPage() {
    return window.location.pathname.startsWith('/auth');
  }

  constructor() {}
}
