import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Authenticate } from '@app/authentication/state/authentication.actions';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent {
  formGroup: FormGroup;
  submitted = false;

  constructor(private fb: FormBuilder, private store: Store) {
    this.formGroup = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  login() {
    this.submitted = true;

    if (this.formGroup.status === 'VALID') {
      const values = this.formGroup.getRawValue();
      this.store.dispatch(new Authenticate(values.username, values.password));
    }
  }
}
