import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ForceResetPassword } from '@app/authentication/state/authentication.actions';

@Component({
  selector: 'app-force-reset',
  templateUrl: './force-reset.component.html',
  styleUrls: ['./force-reset.component.scss'],
})
export class ForceResetComponent {
  formGroup: FormGroup;
  submitted = false;

  constructor(private fb: FormBuilder, private store: Store) {
    this.formGroup = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      oldPassword: ['', [Validators.required, Validators.minLength(3)]],
      newPassword: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  reset() {
    this.submitted = true;

    if (this.formGroup.status === 'VALID') {
      const values = this.formGroup.getRawValue();
      this.store.dispatch(new ForceResetPassword(values.username, values.oldPassword, values.newPassword));
    }
  }
}
