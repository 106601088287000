import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { BaseState } from '@shared/state/base-state';
import { ToastrService } from 'ngx-toastr';
import { Authenticate, ForceResetPassword, IsAuthenticated } from '@app/authentication/state/authentication.actions';
import { AuthService } from '@shared/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TokenExpired } from '@shared/state/base-actions';

export interface AuthenticationStateModel {
  authenticated: boolean;
}

const AUTHENTICATION_TOKEN: StateToken<AuthenticationStateModel> = new StateToken('authentication');

@State<AuthenticationStateModel>({
  name: AUTHENTICATION_TOKEN,
  defaults: {
    authenticated: false,
  },
})
@Injectable()
export class AuthenticationState extends BaseState {
  @Selector() static authenticated(state: AuthenticationStateModel) {
    return state.authenticated;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    toastr: ToastrService,
    zone: NgZone
  ) {
    super(toastr, zone);
  }

  @Action(Authenticate)
  authenticate(ctx: StateContext<AuthenticationStateModel>, action: Authenticate) {
    this.runInZone(() => {
      this.spinner.show();
      this.authService
        .authenticate(action.username, action.password)
        .then((result) => {
          if ('token' in result) {
            sessionStorage.setItem('sbu_token', result.token);
            ctx.patchState({
              authenticated: true,
            });
            this.router.navigate(['/devices']);
          } else {
            this.router.navigate(['/auth/force_reset']);
          }
        })
        .catch((reason) => {
          this.showError(reason.message);
        })
        .finally(() => this.spinner.hide());
    });
  }

  @Action(ForceResetPassword)
  forceResetPassword(ctx: StateContext<AuthenticationStateModel>, action: ForceResetPassword) {
    this.runInZone(() => {
      this.spinner.show();
      this.authService
        .forceResetPassword(action.username, action.oldPassword, action.newPassword)
        .then(() => {
          this.showMessage('Password reset successfully');
          this.router.navigate(['/auth']);
        })
        .catch((reason) => {
          this.showError(reason);
        })
        .finally(() => this.spinner.hide());
    });
  }

  @Action(IsAuthenticated)
  isAuthenticated(ctx: StateContext<AuthenticationStateModel>, action: IsAuthenticated) {
    ctx.patchState({
      authenticated: action.authenticated,
    });
  }

  @Action(TokenExpired)
  tokenExpired(ctx: StateContext<AuthenticationStateModel>) {
    sessionStorage.clear();
    ctx.patchState({
      authenticated: false,
    });
    this.router.navigate(['/auth']);
  }
}
