export class Authenticate {
  static readonly type = '[Authentication] Authenticate';
  constructor(public username: string, public password: string) {}
}

export class IsAuthenticated {
  static readonly type = '[Authentication] Is Authenticated';
  constructor(public authenticated: boolean) {}
}

export class ForceResetPassword {
  static readonly type = '[Authentication] Force Reset Password';
  constructor(public username: string, public oldPassword: string, public newPassword: string) {}
}
