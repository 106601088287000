import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[simpleColumnTemplate]'
})
export class SimpleColumnTemplateDirective {

  @Input('simpleColumnTemplate') public attribute!: string;

  constructor(public template: TemplateRef<any>) { }

}
