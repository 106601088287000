<main class="auth-container">
  <img class="mb-4" src="assets/standbyu.png" alt />
  <h6>Reset Password</h6>
  <form [formGroup]="formGroup">
    <input
      class="form-control mb-2"
      autocomplete="false"
      [ngClass]="{ 'is-invalid': submitted && formGroup.controls['username'].errors }"
      formControlName="username"
      placeholder="email address"
    />
    <input
      class="form-control mb-2"
      autocomplete="false"
      type="password"
      [ngClass]="{ 'is-invalid': submitted && formGroup.controls['oldPassword'].errors }"
      formControlName="oldPassword"
      placeholder="old password"
    />
    <input
      class="form-control mb-3"
      autocomplete="false"
      type="password"
      [ngClass]="{ 'is-invalid': submitted && formGroup.controls['newPassword'].errors }"
      formControlName="newPassword"
      placeholder="new password"
    />
    <button class="w-100 btn btn-primary" (click)="reset()">Reset</button>
  </form>
</main>
