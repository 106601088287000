import jwt_decode from 'jwt-decode';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Store } from '@ngxs/store';
import { IsAuthenticated } from '@app/authentication/state/authentication.actions';

export function appInitializer(ngZone: NgZone, router: Router, store: Store) {
  return () => {
    const token = sessionStorage.getItem('sbu_token');
    if (token) {
      const decoded: any = jwt_decode(token);
      const now = DateTime.now();

      if (now.toUnixInteger() > decoded.exp) {
        toAuth(ngZone, router);
        store.dispatch(new IsAuthenticated(false));
      } else {
        store.dispatch(new IsAuthenticated(true));
      }
    } else {
      toAuth(ngZone, router);
    }
  };
}

function toAuth(ngZone: NgZone, router: Router) {
  ngZone.run(() => {
    router.navigate(['/auth']);
  });
}
