<ng-container [ngSwitch]="(authenticated$ | async) && !authPage">
  <div *ngSwitchCase="true" class="container-fluid">
    <div class="row flex-nowrap">
      <div class="col-auto col-md-2 min-vh-100 sidebar">
        <div class="d-flex justify-content-center mb-4">
          <img src="assets/standbyu.png" alt="StandbyU" height="128" />
        </div>
        <ul class="nav nav-pills flex-column">
          <li class="nav-item">
            <a class="nav-item nav-link" routerLink="/devices" routerLinkActive="active">Devices</a>
          </li>
          <li class="nav-item">
            <a class="nav-item nav-link" routerLink="/candc" routerLinkActive="active">Connect&Check</a>
          </li>
        </ul>
      </div>
      <div class="col content-wrapper">
        <app-header></app-header>
        <div class="content-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchDefault>
    <router-outlet></router-outlet>
  </div>
</ng-container>
