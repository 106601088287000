import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-simple-confirm',
  templateUrl: './simple-confirm.component.html',
  styleUrls: ['./simple-confirm.component.scss'],
})
export class SimpleConfirmComponent {
  @Input() body!: string;
  @Input() title!: string;

  constructor(public activeModal: NgbActiveModal) {}

  no() {
    this.activeModal.close(false);
  }

  yes() {
    this.activeModal.close(true);
  }
}
