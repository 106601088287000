<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="no()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-1">
      <i class="fa-solid fa-triangle-exclamation fa-xl" style="color: #ff0000"></i>
    </div>
    <div class="col">{{ body }}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="no()">No</button>
  <button ngbAutoFocus type="button" class="btn btn-primary" (click)="yes()">Yes</button>
</div>
