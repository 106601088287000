import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SecureAuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private spinner: NgxSpinnerService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let sbuToken = sessionStorage.getItem('sbu_token');
    if (sbuToken === null) {
      this.spinner.hide();
      this.router.navigate(['/auth']);
      return throwError(() => new Error('Authorization error'));
    }

    // TODO: update infrastructure to allow cache headers
    const httpHeaders = req.headers.append('Content-Type', 'application/json').append('Authorization', sbuToken);
    //.append('Cache-Control', 'no-cache')
    //.append('Pragma', 'no-cache')
    //.append('Expires', '-1')

    req = req.clone({ headers: httpHeaders });
    return next.handle(req);
  }
}
