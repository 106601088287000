import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '../logger.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store } from '@ngxs/store';
import { TokenExpired } from '@shared/state/base-actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private modal: NgbModal, private spinner: NgxSpinnerService, private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if ('name' in response && response['name'] === 'HttpErrorResponse') {
      if (response['status'] === 0) {
        sessionStorage.clear();
        this.modal.dismissAll();
        this.spinner.hide();
        this.store.dispatch(new TokenExpired());
      }
    }

    throw response;
  }
}
