import { Injectable } from '@angular/core';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { environment } from '@env/environment';

@Injectable()
export class AuthService {
  private readonly userPool: AWSCognito.CognitoUserPool;

  constructor() {
    this.userPool = new AWSCognito.CognitoUserPool({
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoClientId,
    });
    // AWS.config.region = config.region
  }

  authenticate(username: string, password: string): Promise<any> {
    const authenticationData = { Username: username, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);

    return new Promise(function (resolve, reject) {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session: any) => {
          resolve({
            token: session.getIdToken().getJwtToken(),
          });
        },
        onFailure: reject,
        newPasswordRequired: () => {
          resolve({});
        },
      });
    });
  }

  forceResetPassword(username: string, oldPassword: string, newPassword: string) {
    const authenticationData = { Username: username, Password: oldPassword };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);

    return new Promise(function (resolve, reject) {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: reject,
        onFailure: reject,
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified;
          delete userAttributes.email;

          cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: () => resolve({}),
            onFailure: (err) => reject(err),
          });
        },
      });
    });
  }

  resetPassword(oldPassword: string, newPassword: string) {
    const currentUser = this.getCurrentUser();

    return new Promise(function (resolve, reject) {
      if (!currentUser) {
        reject('No current user found');
      } else {
        currentUser.changePassword(oldPassword, newPassword, (err: any, result: any) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        });
      }
    });
  }

  isAuthenticated(): Promise<boolean> {
    let cognitoUser = this.getCurrentUser();

    return new Promise((resolve) => {
      if (cognitoUser != null) {
        cognitoUser.getSession((err: any) => {
          if (err) {
            resolve(false);
          }
          return resolve(true);
        });
      } else {
        resolve(false);
      }
    });
  }

  getCurrentUser() {
    return this.userPool.getCurrentUser();
  }

  getIdToken(callback: any) {
    const currentUser = this.getCurrentUser();
    if (!currentUser) {
      return callback(null, null);
    } else {
      currentUser.getSession((err: any, session: any) => {
        if (err) return callback(err);
        if (session.isValid()) {
          return callback(null, session.getIdToken().getJwtToken());
        }
        callback(Error('Session is invalid'));
      });
    }
  }
}
