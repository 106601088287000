<main class="auth-container">
  <img class="mb-4" src="assets/standbyu.png" alt />
  <form [formGroup]="formGroup">
    <input
      class="form-control mb-2"
      autocomplete="false"
      [ngClass]="{ 'is-invalid': submitted && formGroup.controls['username'].errors }"
      formControlName="username"
      placeholder="email address"
    />
    <input
      class="form-control mb-3"
      autocomplete="false"
      type="password"
      [ngClass]="{ 'is-invalid': submitted && formGroup.controls['password'].errors }"
      formControlName="password"
      placeholder="password"
    />
    <button class="w-100 btn btn-primary" (click)="login()">Login</button>
  </form>
</main>
