import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationRoutingModule } from '@app/authentication/authentication-routing.module';
import { AuthenticationComponent } from '@app/authentication/authentication.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { AuthenticationState } from '@app/authentication/state/authentication.state';
import { ForceResetComponent } from './force-reset/force-reset.component';

@NgModule({
  declarations: [AuthenticationComponent, ForceResetComponent],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    NgxsModule.forFeature([AuthenticationState]),
    ReactiveFormsModule,
  ],
})
export class AuthenticationModule {}
